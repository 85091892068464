import InternalLink from '../common/internalLink'
import { InstagramIcon, LinkedInIcon, TwitterIcon } from '../icons'
import { BlackFillHorizontalGlyph, BlackHorizontalGlyph } from '../logos'
import { TypographyLabel } from '../typography'

const Footer = () => {
	return (
		<div className="mt-16 w-full bg-white dark:bg-dark">
			<div className="mb-6 ml-4 sm:hidden">
				<BlackHorizontalGlyph className="flex dark:hidden" />
				<BlackFillHorizontalGlyph className="hidden dark:flex" />
			</div>
			<div className="mb-4 grid w-full grid-cols-2 sm:grid-cols-5">
				<div className="md:px6 mt-8 grid w-full px-4 sm:mt-0 sm:px-4 lg:px-8 xl:px-10">
					<TypographyLabel
						size="xSmall"
						additionalClasses="text-textSecondaryLight group-hover:text-textPrimaryLight dark:text-textSecondaryDark dark:group-hover:text-textPrimaryDark"
					>
						Platform
					</TypographyLabel>
					<TypographyLabel
						size="small"
						additionalClasses="mt-4 text-textPrimaryLight group-hover:text-textSecondaryLight dark:text-textPrimaryDark dark:group-hover:text-textSecondaryDark"
					>
						<a
							rel="noreferrer"
							href="https://www.atomicform.com/lore"
							target="_blank"
						>
							Atomic Lore
						</a>
					</TypographyLabel>
					<TypographyLabel
						size="small"
						additionalClasses="mt-4 text-textPrimaryLight group-hover:text-textSecondaryLight dark:text-textPrimaryDark dark:group-hover:text-textSecondaryDark"
					>
						<a
							rel="noreferrer"
							href="https://www.atomicform.com/scan"
							target="_blank"
						>
							Atomic Scan
						</a>
					</TypographyLabel>
				</div>
				<div className="md:px6 mt-8 grid w-full px-4 sm:mt-0 sm:px-4 lg:px-8 xl:px-10">
					<TypographyLabel
						size="xSmall"
						additionalClasses="text-textSecondaryLight group-hover:text-textPrimaryLight dark:text-textSecondaryDark dark:group-hover:text-textPrimaryDark"
					>
						Resources
					</TypographyLabel>
					<TypographyLabel
						size="small"
						additionalClasses="mt-4 text-textPrimaryLight group-hover:text-textSecondaryLight dark:text-textPrimaryDark dark:group-hover:text-textSecondaryDark"
					>
						<a
							rel="noreferrer"
							href="https://www.atomicform.com/"
							target="_blank"
						>
							Documentation
						</a>
					</TypographyLabel>
					<TypographyLabel
						size="small"
						additionalClasses="mt-4 text-textPrimaryLight group-hover:text-textSecondaryLight dark:text-textPrimaryDark dark:group-hover:text-textSecondaryDark"
					>
						<a
							rel="noreferrer"
							href="https://www.atomicform.com/"
							target="_blank"
						>
							Blog
						</a>
					</TypographyLabel>
					<TypographyLabel
						size="small"
						additionalClasses="mt-4 text-textPrimaryLight group-hover:text-textSecondaryLight dark:text-textPrimaryDark dark:group-hover:text-textSecondaryDark"
					>
						<a
							rel="noreferrer"
							href="https://www.atomicform.com/about"
							target="_blank"
						>
							Partnerships
						</a>
					</TypographyLabel>
				</div>
				<div className="md:px6 mt-8 grid w-full px-4 sm:mt-0 sm:px-4 lg:px-8 xl:px-10">
					<TypographyLabel
						size="xSmall"
						additionalClasses="text-textSecondaryLight group-hover:text-textPrimaryLight dark:text-textSecondaryDark dark:group-hover:text-textPrimaryDark"
					>
						Company
					</TypographyLabel>
					<TypographyLabel
						size="small"
						additionalClasses="mt-4 text-textPrimaryLight group-hover:text-textSecondaryLight dark:text-textPrimaryDark dark:group-hover:text-textSecondaryDark"
					>
						<a
							rel="noreferrer"
							href="https://www.atomicform.com/about"
							target="_blank"
						>
							FAQs
						</a>
					</TypographyLabel>
					<TypographyLabel
						size="small"
						additionalClasses="mt-4 text-textPrimaryLight group-hover:text-textSecondaryLight dark:text-textPrimaryDark dark:group-hover:text-textSecondaryDark"
					>
						<a
							rel="noreferrer"
							href="https://www.atomicform.com/about"
							target="_blank"
						>
							About
						</a>
					</TypographyLabel>
					<TypographyLabel
						size="small"
						additionalClasses="mt-4 text-textPrimaryLight group-hover:text-textSecondaryLight dark:text-textPrimaryDark dark:group-hover:text-textSecondaryDark"
					>
						<a
							rel="noreferrer"
							href="https://www.atomicform.com/careers"
							target="_blank"
						>
							Careers
						</a>
					</TypographyLabel>
				</div>
				<div className="md:px6 mt-8 grid w-full px-4 sm:mt-0 sm:px-4 lg:px-8 xl:px-10">
					<TypographyLabel
						size="xSmall"
						additionalClasses="text-textSecondaryLight group-hover:text-textPrimaryLight dark:text-textSecondaryDark dark:group-hover:text-textPrimaryDark"
					>
						Product
					</TypographyLabel>
					<TypographyLabel
						size="small"
						additionalClasses="mt-4 text-textPrimaryLight group-hover:text-textSecondaryLight dark:text-textPrimaryDark dark:group-hover:text-textSecondaryDark"
					>
						<InternalLink to="/termsAndConditions">
							Terms and conditions
						</InternalLink>
					</TypographyLabel>
					<TypographyLabel
						size="small"
						additionalClasses="mt-4 text-textPrimaryLight group-hover:text-textSecondaryLight dark:text-textPrimaryDark dark:group-hover:text-textSecondaryDark"
					>
						<InternalLink to="/privacyPolicy">Privacy policy</InternalLink>
					</TypographyLabel>
					<TypographyLabel
						size="small"
						additionalClasses="mt-4 text-textPrimaryLight group-hover:text-textSecondaryLight dark:text-textPrimaryDark dark:group-hover:text-textSecondaryDark"
					>
						<InternalLink to="/cookiePolicy">Cookie policy</InternalLink>
					</TypographyLabel>
				</div>
				<div className="md:px6 relative grid w-full px-4 sm:px-4 lg:px-8 xl:px-10">
					<div className="md:px6 absolute right-0 top-0 mx-4 hidden sm:mx-4 sm:block lg:mx-8 xl:mx-10">
						<BlackHorizontalGlyph className="flex dark:hidden" />
						<BlackFillHorizontalGlyph className="hidden dark:flex" />
					</div>
				</div>
			</div>
			<div
				className={`md:px6 relative mx-4 flex w-auto border-t border-dotted border-textSecondaryLight py-4 dark:border-uiPrimaryDark sm:mx-4 lg:mx-8 xl:mx-10`}
			>
				<TypographyLabel
					size="small"
					additionalClasses="text-textPrimaryLight group-hover:text-textSecondaryLight dark:text-textPrimaryDark dark:group-hover:text-textSecondaryDark"
				>
					© 2024 Atomic Form
				</TypographyLabel>
				<div className="absolute right-0 flex text-textPrimaryLight dark:text-textPrimaryDark">
					<a
						rel="noreferrer"
						href="https://www.linkedin.com/company/atomic-form/"
						target="_blank"
					>
						<LinkedInIcon />
					</a>
					{/*<DiscordIcon />*/}
					<a
						rel="noreferrer"
						href="https://www.instagram.com/atomicform/"
						target="_blank"
					>
						<InstagramIcon />
					</a>
					<a
						rel="noreferrer"
						href="https://twitter.com/atomicform"
						target="_blank"
					>
						<TwitterIcon />
					</a>
				</div>
			</div>
		</div>
	)
}

export default Footer
